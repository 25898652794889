
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { deepCopy } from '@/utils/general'
  import { Legend } from '../Components/Legend'
  import { fixColor } from '@/utils/dashboards'
  import { Criteria } from './Criteria'

@Component
  export default class DonutChartByCriteria extends BaseChart {
  @Prop() records: Record<string, any>
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: undefined }) pathToValue
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop() criterias: Criteria[]

  @Prop({ default: false, type: Boolean }) smallChart
  @Prop({ default: false, type: Boolean }) horizontal

  categories: Legend[] = []

  // Methods
  async getData () {
    if (this.debugging) console.log('RECORDS', this.records)
    if (!this.records) return

    const { pathToValue, getObjectAttribute, dataTransformer } = this
    let records = deepCopy(this.records?.records?.aggregates?.nodes || this.records?.records?.aggregate?.nodes || this.records?.records || this.records || [])

    if (dataTransformer) {
      records = await dataTransformer(records)
    }

    this.categories = this.criterias.map((criteria): Legend => {
      const filteredByCriteria = records.filter(criteria.filter)

      let value = 0
      if (pathToValue) {
        filteredByCriteria.forEach(record => {
          const sumValue = getObjectAttribute(record, pathToValue)
          value += sumValue || 0
        })
      } else {
        value = filteredByCriteria.length
      }
      return {
        name: criteria.name,
        color: fixColor(criteria.color),
        value,
        records: this.pathToSubcategory ? filteredByCriteria : undefined,
        pathToSubcategory: this.pathToSubcategory,
      }
    })

    this.series = this.categories.map(item => item.value || 0)
    this.chartOptions = {
      labels: this.categories.map(item => item.name),
      colors: this.categories.map(item => item.color),
      title: {
        align: 'center',
        style: {
          fontFamily: 'Poppins',
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
          },
        },
      },
    }
  }

  // Getters

  // Watchers
  @Watch('records', { immediate: true, deep: true })
  onDataChange () {
    this.getData()
  }
  }

