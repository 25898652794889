var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dashboard-card',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('period-records',{attrs:{"process-records":_vm.chartData?.currentMonth?.stock,"process-settings":_vm.chartData?.settings?.stock,"target-field-name":"capacity","default-target":50,"title":"Patio","filtering-method":record => record.status.status.name !== 'retired',"inverse-colors":true}}),_c('period-records-utility',{attrs:{"additive-records":[{
        records: _vm.chartData?.currentMonth?.stock,
        path: ['stock', 'stockDetail', 'totalCost'],
        filteringMethod: record =>
          record.status.status.name !== 'retired'
          && record.stock.stockDetail.acquisitionType.name !== 'consignment',
      }],"sustractive-records":[],"process-settings":[_vm.chartData?.settings?.stock],"default-targets":[400000000],"target-field-names":['capital'],"is-currency":true,"title":"Capital","inverse-colors":true}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }