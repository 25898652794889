
  import Component from 'vue-class-component'
  import { BaseChart } from './baseChart'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { lowOpacityColor } from '@/utils/dashboards'
  import { mapActions } from 'vuex'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class DailyStock extends BaseChart {
  @Prop() stock
  @Prop() prevMonthStock
  @Prop() dates
  statuses = []

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  get limitDates () {
    const currentDate = dayjs()
    const stock = this.stock?.records || this.stock
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      if (!stock) {
        return {
          start: this.dates.start,
          end: currentDate.format('YYYY-MM-DD'),
        }
      } else {
        let dates = stock.map(item => dayjs(item.reportDate).format('YYYY-MM-DD'))
        dates = dates.sort()
        return {
          start: this.dates.start,
          end: dates[dates.length - 1],
        }
      }
    } else return this.dates
  }

  getMeanByStatus (status) {
    const stock = this.stock?.records || this.stock
    if (!stock) return
    let filteredByStatus = stock.filter(item => item.processStatus?.status?.name === status)
    if (status === 'reserved') {
      const reservationProcess = stock.filter(item => item.processStatus?.status?.name === 'reservation_process')
      filteredByStatus = [...filteredByStatus, ...reservationProcess]
    }
    const limitDates = this.generateDayRange(dayjs(this.limitDates.start), dayjs(this.limitDates.end))
    let sum = 0
    limitDates.forEach(date => {
      sum += filteredByStatus.filter(item => dayjs(item.reportDate).isSame(dayjs(date), 'day')).length
    })
    sum /= limitDates.length
    return Math.round(sum)
  }

  getMeanStock () {
    const stock = this.stock?.records || this.stock
    if (!stock) {
      return
    }
    const limitDates = this.generateDayRange(dayjs(this.limitDates.start), dayjs(this.limitDates.end))
    let sum = 0
    limitDates.forEach(date => {
      sum += stock.filter(item => dayjs(item.reportDate).isSame(dayjs(date), 'day')).length
    })
    sum /= limitDates.length
    return Math.round(sum)
  }

  get categories () {
    return this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    ).slice(0, -1)
  }

  get limitRange () {
    return this.generateDayRange(
      dayjs(this.limitDates.start), dayjs(this.limitDates.end)
    )
  }

  get currentMonthRange () {
    return this.generateDayRange(
      dayjs(this.limitDates.start), dayjs(this.limitDates.start).endOf('month')
    )
  }

  async joinStockReserves (records: Record<string, any>[]): Promise<Record<string, any>> {
    const statusesToRemove = ['retired', 'sold']
    records = records.filter(record => {
      if (record.processStatus) {
        return !statusesToRemove.includes(record.processStatus.status.name)
      } else {
        return !statusesToRemove.includes(record.status.name)
      }
    })
    const reserveStatus = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        status: {
          name: { _eq: 'reserved' },
        },
      },
    }))[0].status
    for (let i = 0; i < records.length; i++) {
      if (records[i].processStatus) {
        if (records[i].processStatus.status.name === 'reservation_process') {
          records[i].processStatus.status = reserveStatus
        }
      } else if (records[i].status.name === 'reservation_process') {
        records[i].status = reserveStatus
      }
    }

    return records
  }

  async getData () {
    let stock = this.stock?.records || this.stock
    let prevMonthStock = this.prevMonthStock?.records || this.prevMonthStock
    if (!stock || !prevMonthStock) return

    this.statuses = []
    stock = await this.joinStockReserves(stock)
    prevMonthStock = await this.joinStockReserves(prevMonthStock);
    [...stock, ...prevMonthStock].forEach(item => {
      if (!this.statuses.map(status => status.name).includes(item.processStatus?.status?.name)) {
        this.statuses.push(item.processStatus?.status)
      }
    })

    const currentMonthSeries = this.statuses.map(status => {
      const filteredByStatus = stock.filter(item => {
        return item.processStatus?.status?.name === status.name
      })
      // eslint-disable-next-line no-unused-vars
      const data = this.limitRange.map((_category, categoryIndex) => {
        // eslint-disable-next-line no-unused-vars
        const filteredByDate = filteredByStatus.filter((_item, itemIndex) => {
          return dayjs(filteredByStatus[itemIndex].reportDate).isSame(dayjs(this.limitRange[categoryIndex]), 'day')
        })
        return filteredByDate.length
      })

      this.fillWithNull(data, this.limitRange.length)

      return {
        name: status.description,
        data,
        color: status.color.split(' ')[0],
        type: 'line',
      }
    })

    const prevMonthSeries = this.statuses.map(status => {
      let filteredByStatus = prevMonthStock.filter(item => {
        return item.processStatus?.status?.name === status.name
      })

      if (status.name === 'reserved') {
        const reservationProcess = stock.filter(item => {
          return item.processStatus?.status?.name === 'reservation_process'
        })
        filteredByStatus = [...filteredByStatus, ...reservationProcess]
        this.statuses = this.statuses.filter(status => status.name !== 'reservation_process')
      }
      if (status.name === 'reservation_process') {
        const reserves = stock.filter(item => {
          return item.processStatus?.status?.name === 'reserved'
        })
        filteredByStatus = [...filteredByStatus, ...reserves]
        this.statuses = this.statuses.filter(status => status.name !== 'reserved')
        status.description = 'Reservado'
      }
      const limitRange = this.generateDayRange(
        dayjs(dayjs(this.limitDates.start).subtract(1, 'month')), dayjs(dayjs(this.limitDates.start).subtract(1, 'month')).endOf('month')
      ).slice(0, this.currentMonthRange.length - 1)
      const data = limitRange.map(category => {
        const filteredByDate = filteredByStatus.filter(item => {
          return dayjs(item.reportDate).isSame(dayjs(category), 'day')
        })
        return filteredByDate.length
      })

      this.fillWithNull(data, limitRange.length)

      return {
        name: status.description + ' (mes anterior)',
        data,
        color: status.color.split(' ')[0] + '4C',
        type: 'line',
      }
    })

    this.series = [...currentMonthSeries, ...prevMonthSeries]

    const totals = this.limitRange.map(date => {
      return stock.filter(item => dayjs(item.reportDate).isSame(dayjs(date), 'day')).length
    })

    this.fillWithNull(totals, this.limitRange.length)

    this.series.push({
      name: 'Total',
      data: totals,
      color: lowOpacityColor('#00ff58'),
      type: 'area',
      stroke: {
        curve: 'smooth',
      },
    })

    for (let i = 0; i < this.series.length; i++) {
      this.series[i].data = this.series[i].data.slice(0, this.categories.length)
    }

    this.chartOptions = {
      labels: this.categories,
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 3,
        curve: 'straight',
        dashArray: [0, 0, 0, 0, 3, 3, 3, 3],
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories: this.categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD')
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            return Math.floor(val)
          },
        },
      },
    }
  }

  @Watch('stock', { immediate: false, deep: true })
  @Watch('prevMonthStock', { immediate: false, deep: true })
  @Watch('dates', { immediate: false, deep: true })
  update () {
    this.getData()
  }
  }
