
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { UtilityRecord } from './utilityRecord'
  import { deepCopy, fixPrice } from '@/utils/general'

@Component
  export default class PeriodRecordsUtility extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
  }

  @Prop() additiveRecords!: UtilityRecord[]
  @Prop() sustractiveRecords!: UtilityRecord[]
  @Prop() processSettings!: Record<string, any>[]
  @Prop() targetFieldNames!: string[]
  @Prop() defaultTargets!: number[]
  @Prop({ default: false, type: Boolean }) useDefaultTarget
  @Prop() title!: string

  utility: number = 0
  target: number = 0

  get formatedTarget () {
    return fixPrice(this.target)
  }

  getRecordsUtility (utilityRecords: UtilityRecord[]): number {
    let sum = 0
    utilityRecords
      .forEach(utilityRecord => {
        const { records, path, filteringMethod } = utilityRecord
        if (!records) return
        let utilityItems = deepCopy(records?.records?.aggregate?.nodes || records?.records?.nodes || records?.records || [])
        if (filteringMethod) {
          this.Debug('FILTERING METHOD', filteringMethod)
          utilityItems = utilityItems.filter(filteringMethod)
        }
        utilityItems
          .forEach(record => sum += this.getObjectAttribute(record, path) || 0)
      })
    return sum
  }

  getData () {
    const { targetFieldNames, defaultTargets } = this
    const additiveRecords = this.additiveRecords || []
    const sustractiveRecords = this.sustractiveRecords || []
    const settings = this.processSettings?.map(setting => setting?.config)

    this.Debug('ADDITIVE RECORDS', additiveRecords)
    this.Debug('SUSTRACTIVE RECORDS', sustractiveRecords)

    const additiveUtility = this.getRecordsUtility(additiveRecords)
    const sustractiveUtility = this.getRecordsUtility(sustractiveRecords)

    const utility = additiveUtility - sustractiveUtility
    this.utility = utility

    let recordsTarget = this.useDefaultTarget ? defaultTargets[0] : 0
    if (!this.useDefaultTarget) {
      settings.forEach((setting, index) => {
        recordsTarget += (setting?.projectedUtility || setting?.resource)?.[targetFieldNames[index]] || defaultTargets[index]
      })
    }
    this.target = recordsTarget

    const percentage = (utility / recordsTarget) * 100

    this.brief = `${fixPrice(utility)}`
    this.series = [Math.round(percentage) || 0]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: '14px',
              fontWeight: 500,
              color: '#737373',
              offsetY: this.title.length > 9 ? 28 : 32,
            },
            value: {
              show: true,
              fontSize: '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -1,
            },
          },
        },
      },
      labels: [this.title],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
  }

  // Getters
  get headerTitle () {
    return this.title.split(' ').join('_')
  }

  get watchData () {
    const { additiveRecords, sustractiveRecords, processSettings, defaultTargets } = this
    return {
      additiveRecords,
      sustractiveRecords,
      processSettings,
      defaultTargets,
    }
  }

  // Watchers
  @Watch('utility')
  onUtilityChange () {
    this.$emit('utility-change', this.utility)
  }

  @Watch('watchData', { immediate: true, deep: true })
  onDataChange () {
    this.getData()
  }
  }
