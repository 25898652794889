import { Component, Vue, Watch } from 'vue-property-decorator'
import { filterDates } from './types'
import { mapActions, mapGetters } from 'vuex'
import { Query } from '@/entities/public/Resource/interfaces'
import { formFilter } from '@/graphql/generated-types'
import GeneralResults from './common/GeneralResults.vue'
import UtilityResults from './common/UtilityResults.vue'
import StockResults from './common/StockResults.vue'
import SalesResults from './common/SalesResults.vue'
import PurchasesResults from './common/PurchasesResults.vue'
import DailyStock from '@/components/dashboards/DailyStock.vue'
import DailyStockValue from '@/components/dashboards/DailyStockValue.vue'
import DashboardCard from '@/components/dashboards/Components/DashboardCard.vue'
import DonutChart from '@/components/dashboards/Generics/DonutChart.vue'
import LineChart from '@/components/dashboards/Generics/LineChart.vue'
import CarsByChassis from '@/components/dashboards/CarsByChassis.vue'
import LeadsByChannel from '@/components/dashboards/LeadsByChannel.vue'
import LeadsByPipeline from '@/components/dashboards/LeadsByPipeline.vue'
import LeadByExecutive from '@/components/dashboards/LeadByExecutive.vue'
import LeadsByTask from '@/components/dashboards/LeadsByTask.vue'
import ABChart from '@/components/dashboards/Generics/ABChart.vue'
import LineChartByCriteria from '@/components/dashboards/Generics/LineChartByCriteria.vue'
import DonutChartByCriteria from '@/components/dashboards/Generics/DonutChartByCriteria.vue'
import { lowOpacityColor } from '@/utils/dashboards'
import FinancialEvaluationReport from '@/components/dashboards/Reports/FinancialEvaluation.vue'
import PurchasesReport from '@/components/dashboards/Reports/Purchases.vue'
import { deepCopy, utcToLocal } from '@/utils/general'
import RecordDetail from '@/components/dashboards/Generics/RecordDetail.vue'
import RecordDetailByCategory from '@/components/dashboards/Generics/RecordDetailByCategory.vue'
import InspectionsReport from '@/components/dashboards/Reports/Inspections.vue'
import SalesReport from '@/components/dashboards/Reports/Sales.vue'
import CreditsReport from '@/components/dashboards/Reports/Credits.vue'
import ReserveReport from '@/components/dashboards/Reports/Reserve.vue'
import CarsByFuel from '@/components/dashboards/CarsByFuel.vue'
import CarsByTransmission from '@/components/dashboards/CarsByTransmission.vue'
import CarsByPrice from '@/components/dashboards/CarsByPrice.vue'
import LoanApplications from '@/components/dashboards/LoanApplications.vue'
import dayjs from 'dayjs'
import { UserData } from '@/store/user/state'

@Component({
  methods: {
    ...mapActions('dashboard', [
      'getPurchases',
      'getInspections',
      'getSales',
      'getLoanEvaluationsAggregates',
      'getReserves',
      'getSoldSales',
      'getUtilityCredits',
      'getDailyStock',
      'getStockStatus',
      'getStockSpecs',
      'getLeads',
      'getExecutivesForReports',
      'getFinancialReports',
      'getPurchasesReports',
      'getSalesReports',
      'getCreditsReports',
      'getInspectionReports',
      'getReserveReports',
      'getSignedLoans',
      'getFinancing',
      'getReservesQuery',
      'getStock',
    ]),
    ...mapActions('resources/form', ['fetchData']),
  },
  computed: {
    ...mapGetters('user', ['user', 'roles']),
  },
  components: {
    // COMPONENTES
    DashboardCard,

    // GRUPOS DE GRAFICOS
    GeneralResults,
    UtilityResults,
    StockResults,
    PurchasesResults,
    SalesResults,

    // GRAFICOS GENERICOS
    DonutChart,
    LineChart,
    ABChart,
    LineChartByCriteria,
    DonutChartByCriteria,
    RecordDetail,
    RecordDetailByCategory,

    // GRAFICOS ESPECIFICOS
    DailyStock,
    DailyStockValue,
    LeadsByChannel,
    LeadsByPipeline,
    LeadByExecutive,
    LeadsByTask,
    FinancialEvaluationReport,
    InspectionsReport,
    PurchasesReport,
    SalesReport,
    CreditsReport,
    ReserveReport,
    CarsByChassis,
    CarsByFuel,
    CarsByTransmission,
    CarsByPrice,
    LoanApplications,
  },
})
export default class Dashboard extends Vue {
  getInspections!: (variable) => Promise<Record<string, any>>
  getPurchases!: (variable) => Promise<Record<string, any>>
  getSales!: (variable) => Promise<Record<string, any>>
  getLoanEvaluationsAggregates!: (variable) => Promise<Record<string, any>>
  getReserves!: (variable) => Promise<Record<string, any>>
  getReservesQuery!: (variable) => Promise<Record<string, any>>
  getSoldSales!: (variable) => Promise<Record<string, any>>
  getUtilityCredits!: (variable) => Promise<Record<string, any>>
  getDailyStock!: (variable) => Promise<Record<string, any>>
  getStockStatus!: (variable) => Promise<Record<string, any>>
  getStockSpecs!: (variable) => Promise<Record<string, any>>
  getLeads!: (variable) => Promise<Record<string, any>>
  getExecutivesForReports!: () => Promise<Record<string, any>>
  getFinancialReports!: () => Promise<Record<string, any>>
  getPurchasesReports!: () => Promise<Record<string, any>>
  getSalesReports!: () => Promise<Record<string, any>>
  getCreditsReports!: () => Promise<Record<string, any>>
  getReserveReports!: () => Promise<Record<string, any>>
  getInspectionReports!: () => Promise<Record<string, any>>
  getSignedLoans!: (variable) => Promise<Record<string, any>>
  getFinancing!: (variable) => Promise<Record<string, any>>
  getStock!: (variable) => Promise<Record<string, any>>

  idEmployee: number = null
  user!: UserData

  filterGenerator!: (dates: filterDates) => Record<string, any>
  specificFilterGenerator!: (dates: filterDates, idEmployee: number) => Record<string, any>
  getData!: () => void

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  chartData: Record<string, any> = {
    currentMonth: {},
    prevMonth: {},
    lastYear: {},
    settings: {},
  }

  specificChartData: Record<string, any> = {
    currentMonth: {},
    prevMonth: {},
    lastYear: {},
    settings: {},
  }

  @Watch('user', { immediate: true, deep: true })
  async onUserChange (val) {
    if (val) {
      const employee = await this.fetchData({
        query: { name: 'find', model: 'Employee' },
        filter: { person: { user: { id: { _eq: val.id } } } },
      })
      this.idEmployee = employee?.[0]?.id
    }
  }

  @Watch('currentMonth', { deep: true })
  async onMonthChange () {
    this.chartData = {
      currentMonth: {},
      prevMonth: {},
      lastYear: {},
      settings: this.chartData.settings,
    }
    this.specificChartData = {
      currentMonth: {},
      prevMonth: {},
      lastYear: {},
      settings: this.chartData.settings,
    }
    await this.getData()
  }

  // Methods
  async created () {
    await this.getSettings()
    await this.getData()
  }

  async submitQuery (query: {
    category: 'currentMonth' | 'prevMonth' | 'lastYear' | 'settings'
    field: string
    queryMethod: Function
    queryFilter: Record<string, any>
  }, specific = false) {
    const { $set } = this
    const { category, field, queryFilter, queryMethod } = query
    if (!(specific ? this.specificChartData : this.chartData)[category][field] && queryMethod) {
      const promiseResult = queryFilter ? await queryMethod(queryFilter) : await queryMethod()
      const parsedData = promiseResult?.records?.aggregate?.nodes ||
        promiseResult?.records?.nodes || promiseResult?.records || promiseResult || []
      $set((specific ? this.specificChartData : this.chartData)[category], field, parsedData)
    }
  }

  async submitQueries (queries: [{
    category: 'currentMonth' | 'prevMonth' | 'lastYear' | 'settings'
    field: string
    queryMethod: Function
    queryFilter: Record<string, any>
  }], specific = false) {
    queries.forEach(query => this.submitQuery(query, specific))
  }

  async getGeneralResults () {
    this.$set(this.chartData.currentMonth, 'inspections', await this.getInspections(this.currentMonthFilters.startEndDate))
    this.$set(this.chartData.currentMonth, 'purchases', await this.getPurchases(this.currentMonthFilters.stockCreatedDate))
    this.$set(this.chartData.currentMonth, 'sales', await this.getSales(this.currentMonthFilters.startEndDate))
    this.$set(this.chartData.currentMonth, 'reserves', await this.getReserves(this.currentMonthFilters.startEndDate))
    this.$set(this.chartData.currentMonth, 'evaluations', await this.getFinancing(this.currentMonthFilters.loanApplications))
    this.$set(this.chartData.currentMonth, 'soldPurchases', await this.getPurchases(this.currentMonthFilters.soldPurchases))
    this.$set(this.chartData.currentMonth, 'soldSales', await this.getSoldSales(this.currentMonthFilters.soldSales))
    this.$set(this.chartData.currentMonth, 'utilityCredits', await this.getUtilityCredits(this.currentMonthFilters.utilityCredits))
    this.$set(this.chartData.currentMonth, 'signedCredits', await this.getLoanEvaluationsAggregates(this.currentMonthFilters.signedCredits))
    this.$set(this.chartData.currentMonth, 'stock', await this.getStock(this.currentMonthFilters.stockSpecs))
  }

  async getSettings () {
    const settingsFields = [
      { name: 'purchase', table: 'purchase_order' },
      { name: 'sale', table: 'sale_order' },
      { name: 'loanEvaluations', table: 'evaluation' },
      { name: 'inspection', table: 'inspection' },
      { name: 'reserve', table: 'reserve' },
      { name: 'stock', table: 'stock' },
    ]
    const settings: Record<string, any> = {}
    for (const field of settingsFields) {
      settings[field.name] = (await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: field.table } },
      }))[0]
    }
    this.$set(this.chartData, 'settings', settings)
    this.$set(this.chartData.settings, 'executives', await this.getExecutivesForReports())
  }

  getObjectAttribute (object, path: string[]): any {
    const pathCopy = deepCopy(path)
    if (pathCopy.length > 0) {
      const attribute = pathCopy.shift()
      const returnValue = object[attribute]
      if (returnValue) {
        return this.getObjectAttribute(returnValue, pathCopy)
      } else {
        return undefined
      }
    }
    return object
  }

  // Queries

  // Getters
  get dates () {
    return {
      start: utcToLocal(dayjs(this.currentMonth._gte)).format('YYYY-MM-DDTHH:mm:ss'),
      end: utcToLocal(dayjs(this.currentMonth._lte)).format('YYYY-MM-DDTHH:mm:ss'),
    }
  }

  get lastYearDates () {
    return {
      start: utcToLocal(dayjs(this.lastYear._gte)).format('YYYY-MM-DDTHH:mm:ss'),
      end: utcToLocal(dayjs(this.lastYear._lte)).format('YYYY-MM-DDTHH:mm:ss'),
    }
  }

  // General Filters
  get currentMonthFilters (): Record<string, any> {
    return this.filterGenerator(this.currentMonth)
  }

  get prevMonthFilters (): Record<string, any> {
    return this.filterGenerator(this.prevMonth)
  }

  get lastYearFilters (): Record<string, any> {
    return this.filterGenerator(this.lastYear)
  }

  get currentMonth (): filterDates {
    return this.$store.getters['dashboard/currentMonth']
  }

  // Specific Filters
  get specificCurrentMonthFilters (): Record<string, any> {
    return this.specificFilterGenerator(this.currentMonth, this.idEmployee)
  }

  get specificPrevMonthFilters (): Record<string, any> {
    return this.specificFilterGenerator(this.prevMonth, this.idEmployee)
  }

  get specificLastYearFilters (): Record<string, any> {
    return this.specificFilterGenerator(this.lastYear, this.idEmployee)
  }

  get specificCurrentMonth (): filterDates {
    return this.$store.getters['dashboard/currentMonth']
  }

  get prevMonth (): filterDates {
    return this.$store.getters['dashboard/prevMonth']
  }

  get lastYear (): filterDates {
    return this.$store.getters['dashboard/lastYear']
  }

  get totalsColor () {
    return lowOpacityColor('#00ff58')
  }

  // Watchers
}
