
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { fixColor, lowOpacityColor } from '@/utils/dashboards'
  import { BaseChart } from '../baseChart'
  import { Legend } from '../Components/Legend'
  import { Criteria } from './Criteria'

// Grafico de linea para un solo tipo de dato que se separa por un criterio dado
@Component
  export default class LineChartByCriteria extends BaseChart {
  @Prop() currentMonthRecords
  @Prop() prevMonthRecords
  @Prop() pathToDate: string[]
  @Prop() pathToValue: string[]
  @Prop() criterias: Criteria[]
  @Prop({ default: false, type: Boolean }) monthly
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: false, type: Boolean }) nullZeroValues
  @Prop({ default: true, type: Boolean }) showPrevMonth
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: 'total', type: String }) totalSufix
  @Prop({ default: '', type: String }) labelSufix
  @Prop({ default: 'line', type: String }) chartType
  @Prop({ default: true, type: Boolean }) colorLabels
  @Prop({ default: true, type: Boolean }) showTotals
  @Prop({ default: false, type: Boolean }) verticalLegend
  @Prop({ default: false, type: Boolean }) accumulated // Define si el grafico es de acumulados
  @Prop({ default: true, type: Boolean }) showTotal
  @Prop({ default: true, type: Boolean }) sortLegends
  @Prop() dates

  legends: Legend[] = []

  get limitDates () {
    const currentDate = dayjs()
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      return {
        start: this.dates.start,
        end: currentDate.format('YYYY-MM-DDTHH:MM:ss'),
      }
    } else {
      return this.dates
    }
  }

  get limitRange () {
    const { start, end } = this.limitDates
    const prevMonthStart = dayjs(start).subtract(1, 'month')
    const prevMonthEnd = prevMonthStart.endOf('month')

    const fullCurrentMonth = this.generateDayRange(start, dayjs(start).endOf('month'), this.monthly)

    const currentMonth = this.generateDayRange(start, end, this.monthly)
    const prevMonth = this.generateDayRange(prevMonthStart, prevMonthEnd, this.monthly)
    return {
      currentMonth: currentMonth.slice(0, currentMonth.length - 1),
      prevMonth: prevMonth.slice(0, fullCurrentMonth.length - 1),
    }
  }

  get currentMonth () {
    const { start } = this.limitDates

    return {
      start: dayjs(start).startOf('month'),
      end: dayjs(start).endOf('month'),
    }
  }

  get currentMonthLength () {
    const { start, end } = this.currentMonth

    return end.diff(start, 'day')
  }

  get limitLength () {
    return this.limitDates.currentMonth.length
  }

  getDataByCategory (records, category, path) {
    return records.filter(record => {
      return this.getObjectAttribute(record, path).id === category.id
    })
  }

  async getData () {
    const {
      criterias,
      limitRange,
      getObjectAttribute,
      pathToDate,
      pathToValue,
      isCurrency,
      monthly,
      months,
      showTotal,
    } = this

    const parsedRecords = {
      currentMonth: this.currentMonthRecords?.records?.aggregate?.nodes || this.currentMonthRecords?.records || this.currentMonthRecords || [],
      prevMonth: this.prevMonthRecords?.records?.aggregate?.nodes || this.prevMonthRecords?.records || this.prevMonthRecords || [],
    }
    this.Debug('RECORDS', parsedRecords)

    if (this.dataTransformer) {
      parsedRecords.currentMonth = await this.dataTransformer(parsedRecords.currentMonth)
      parsedRecords.prevMonth = await this.dataTransformer(parsedRecords.prevMonth)
    }

    const data = criterias.map(criteria => {
      const dataByCategory = {
        currentMonth: parsedRecords.currentMonth.filter(criteria.filter),
        prevMonth: parsedRecords.prevMonth.filter(criteria.filter),
      }

      return {
        name: criteria.name,
        data: {
          currentMonth: limitRange.currentMonth.map(date => {
            const filterByDate = dataByCategory.currentMonth.filter(item => dayjs(getObjectAttribute(item, pathToDate)).isSame(dayjs(date), this.monthly ? 'month' : 'day'))
            if (pathToValue) {
              let sum = 0
              filterByDate.forEach(item => sum += getObjectAttribute(item, pathToValue))
              if (monthly) {
                return Math.round(sum / 30)
              }
              return sum
            } else {
              return filterByDate.length
            }
          }),
          prevMonth: limitRange.prevMonth.map(date => {
            const filterByDate = dataByCategory.prevMonth.filter(item => dayjs(getObjectAttribute(item, pathToDate)).isSame(dayjs(date), this.monthly ? 'month' : 'day'))
            if (pathToValue) {
              let sum = 0
              filterByDate.forEach(item => sum += getObjectAttribute(item, pathToValue))
              if (monthly) {
                return Math.round(sum / 30)
              }
              return sum
            } else {
              if (monthly) {
                return Math.round(filterByDate.length / 30)
              }
              return filterByDate.length
            }
          }),
        },
        color: fixColor(criteria.color),
        type: this.chartType,
      }
    })

    const currentMonthData = data.map(item => {
      const { name, color, type, data } = item
      const { currentMonth } = data
      return {
        name,
        color,
        type,
        data: this.nullZeroValues ? currentMonth.map(item => item === 0 ? null : item) : currentMonth,
      }
    })

    const prevMonthData = data.map(item => {
      const { name, color, type, data } = item
      const { prevMonth } = data
      return {
        name: name + ' (mes anterior)',
        color: lowOpacityColor(color),
        type,
        data: this.nullZeroValues ? prevMonth.map(item => item === 0 ? null : item) : prevMonth,
      }
    })

    this.legends = currentMonthData.map(item => {
      const { name, color, data } = item
      let value = 0
      data.forEach(item => value += item !== null ? item : 0)
      if (monthly) {
        value /= data.filter(item => item !== null).length
      }
      value = Math.round(value)
      return {
        name,
        color,
        value: value || 0,
      }
    })

    this.series = [...currentMonthData, ...(this.showPrevMonth ? prevMonthData : [])]

    if (showTotal) {
      const totals = this.generateTotals(currentMonthData)

      this.series.push({
        name: 'Total',
        data: this.nullZeroValues ? totals.map(item => item === 0 ? null : item) : totals,
        color: lowOpacityColor('#00ff58'),
        type: 'area',
        stroke: {
          curve: 'smooth',
        },
      })

      if (!monthly) {
        const prevMonthTotals = this.generateTotals(prevMonthData)

        this.series.push({
          name: 'Total (mes anterior)',
          data: this.nullZeroValues ? prevMonthTotals.map(item => item === 0 ? null : item) : totals,
          color: '#00ff58',
          type: 'line',
          stroke: {
            curve: 'smooth',
          },
        })
      }
    }

    if (this.accumulated) {
      for (let i = 0; i < this.series.length; i++) {
        this.series[i].data = this.generateAccumulated(this.series[i].data)
      }
    }

    if (!monthly) {
      this.series.forEach(serie => {
        serie.data = this.fillWithNull(serie.data, this.currentMonthLength)
      })
    }
    this.Debug('SERIES', this.series)

    this.chartOptions = {
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories: monthly ? this.limitRange.currentMonth.map(date => dayjs(date)) : this.generateDayRange(this.currentMonth.start, this.currentMonth.end, monthly),
        type: 'category',
        labels: {
          formatter (val) {
            return monthly ? months[dayjs(val).format('MM')].short : dayjs(val).format('DD/MM')
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
        },
      },
    }
  }

  generateTotals (values) {
    const totals = []
    values.forEach(item => {
      item.data.forEach((value, index) => {
        if (!totals[index]) {
          totals[index] = 0
        }
        totals[index] += value
      })
    })
    return totals
  }

  get watchData () {
    const { currentMonthRecords, prevMonthRecords, dates } = this
    return {
      currentMonthRecords,
      prevMonthRecords,
      dates,
    }
  }

  @Watch('watchData', { immediate: false, deep: true })
  update () {
    this.getData()
  }
  }
