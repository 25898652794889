
  import DashboardCard from '@/components/dashboards/Components/DashboardCard.vue'
  import PeriodRecords from '@/components/dashboards/Generics/PeriodRecords.vue'
  import PeriodRecordsUtility from '@/components/dashboards/Generics/PeriodRecordsUtility.vue'
  import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  components: {
    DashboardCard,
    PeriodRecords,
    PeriodRecordsUtility,
  },
})
  export default class StockResults extends Vue {
  @Prop() chartData: Record<string, any>
  @Prop({ default: 'Ocupación de recursos', type: String }) title

  @Prop({ default: undefined, type: Number }) idEmployee
  }
