
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { deepCopy } from '@/utils/general'
  import { Legend } from '../Components/Legend'
  import { fixColor } from '@/utils/dashboards'
  import { channelColors } from '@/utils/channelColors'
  import { financingColors } from '@/utils/financingColors'

@Component
  export default class DonutChart extends BaseChart {
  @Prop() records: Record<string, any>
  @Prop() pathToCategory: string[]
  @Prop({ default: () => ['description'] }) pathToCategoryTitle: string[]
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: undefined }) pathToValue
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: 'categoría', type: String }) categoryName

  @Prop({ default: false, type: Boolean }) smallChart
  @Prop({ default: false, type: Boolean }) horizontal

  categories: Legend[] = []

  // Methods
  async getData () {
    if (!this.records) return
    if (this.debugging) console.log(this.records)

    const { pathToCategory, pathToValue, getObjectAttribute, dataTransformer, getCategories } = this
    let records = deepCopy(this.records?.records?.aggregates?.nodes || this.records?.records?.aggregate?.nodes || this.records?.records?.nodes || this.records?.records || this.records || [])

    if (dataTransformer) {
      records = await dataTransformer(records)
      if (this.debugging) console.log('AFTER TRANSFORM DATA', records)
    }
    const categories = getCategories(records, pathToCategory, this.categoryName, this.debugging)
    if (this.debugging) console.log('CATEGORIES', categories)

    this.categories = categories.map((category): Legend => {
      const filteredByCategory = records.filter(record => getObjectAttribute(record, pathToCategory)?.id === category?.id)

      let value = 0
      if (pathToValue) {
        filteredByCategory.forEach(record => {
          const sumValue = getObjectAttribute(record, pathToValue)
          value += sumValue || 0
        })
      } else {
        value = filteredByCategory.length
      }
      const name = getObjectAttribute(category, this.pathToCategoryTitle)

      return {
        name,
        color: category.color ? fixColor(category.color) : (channelColors[category.name] || financingColors[name]),
        value,
        records: this.pathToSubcategory ? filteredByCategory : undefined,
        pathToSubcategory: this.pathToSubcategory,
        pathToValue: this.pathToSubcategory ? this.pathToValue : undefined,
      }
    })

    if (this.debugging) console.log('PROCESSED CATEGORIES', this.categories)

    this.series = this.categories.map(item => item.value || 0)
    this.chartOptions = {
      labels: this.categories.map(item => item.name),
      colors: this.categories.map(item => item.color),
      title: {
        align: 'center',
        style: {
          fontFamily: 'Poppins',
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
          },
        },
      },
    }
  }

  // Getters

  // Watchers
  @Watch('records', { immediate: true, deep: true })
  onDataChange () {
    this.getData()
  }
  }

