
  import DashboardCard from '@/components/dashboards/Components/DashboardCard.vue'
  import PeriodRecordsUtility from '@/components/dashboards/Generics/PeriodRecordsUtility.vue'
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DashboardCard,
    PeriodRecordsUtility,
  },
})
  export default class UtilityResults extends Vue {
  @Prop() chartData: Record<string, any>
  directUtility = 0

  mounted () {
  }
  }
