export const channelColors = {
  facebook: '#1351AF',
  floor: '#13CE66',
  client_portfolio: '#FAD901',
  chileautos: '#FF9E2C',
  yapo: '#FA5A7D',
  fullmotors: '#67BCBC',
  web_site: '#0033A5',
  mercado_libre: '#00E0FF',
  referred: '#7D67BC',
  genio: '#6185DB',
  instagram: '#F03738',
  dealer: '#7000FF',
  personal_contact: '#C8FF2C',
  facebook_marketplace: '#A497F6',
}
