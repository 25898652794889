
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { Debounce } from '@/utils/decorators'
  import { chassisColors } from '@/utils/chassisColors'

@Component({})
  export default class CarsByChassis extends BaseChart {
  chassis = []
  stockSpecsAttribute = []
  chassisColors = chassisColors
  @Prop() stockSpecs
  @Prop() cars

  getCountByChassis (chassis) {
    return this.stockSpecsAttribute?.filter(car => car.componentValue.value === chassis)?.length
  }

  async getData () {
    if (!this.stockSpecs) return
    const stock = this.stockSpecs.salesStockView
    if (!stock) return

    const carSpecs = this.cars
    this.stockSpecsAttribute = carSpecs

    let chassis = []
    carSpecs?.forEach(car => {
      const carChassis = car.componentValue.value
      if (!chassis.includes(carChassis)) {
        chassis.push(carChassis)
      }
    })
    chassis = chassis.sort((prev, next) => {
      return this.getCountByChassis(next) - this.getCountByChassis(prev)
    })
    this.chassis = chassis
    const colors = chassis.map(chassis => chassisColors[chassis])

    this.series = chassis.map(chassis => {
      return carSpecs?.filter(car => car.componentValue.value === chassis)?.length
    })
    this.chartOptions = {
      colors,
      stroke: {
        lineCap: 'round',
      },
      legend: {
        show: false,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              fontFamily: 'Poppins',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '40px',
              fontWeight: 800,
              color: '#737373',
              fontFamily: 'Poppins',
              offsetY: -17,
            },
          },
        },
      },
      labels: chassis,
      fill: {
        type: 'solid',
      },
    }
  }

  // Getters

  // Watchers
  @Watch('stockSpecs', { immediate: true, deep: true })
  @Watch('cars', { immediate: true, deep: true })
  @Debounce(500)
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
