
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { fixAmountFormatted, fixPrice, utcToLocal } from '@/utils/general'
  import { BaseChart } from '../baseChart'
  import { Legend } from '../Components/Legend'
  import { lowOpacityColor } from '@/utils/dashboards'
  import _ from 'lodash'

interface record {
  records: Record<string, any>,
  name: string,
  pathToDate: string[],
  color?: string,
  type?: string,
  dataTransformer?: Function,
}

@Component
  // Grafico de linea para varios tipos de dato
  export default class ABChart extends BaseChart {
  @Prop() records: record[]
  @Prop() prevMonthRecords: record[]
  @Prop({ default: false, type: Boolean }) monthly // Hace que el grafico sea mensual
  @Prop({ default: false, type: Boolean }) isCurrency // Define si formatea cantidades como moneda
  @Prop({ default: false, type: Boolean }) nullZeroValues // Convierte 0 en null
  @Prop({ default: 'total', type: String }) totalSufix // Sufijo para la barra del total
  @Prop({ default: '', type: String }) labelSufix // Sufijo para los labels de cada categoria
  @Prop({ default: true, type: Boolean }) colorLabels // Define si se colorean los labels de las categorias
  @Prop({ default: true, type: Boolean }) showTotals // Define si muestra los totales de las categorias
  @Prop({ default: true, type: Boolean }) showTotal // Define si muestra el total de todos los datos
  @Prop({ default: false, type: Boolean }) verticalLegend // Define si usa la leyenda vertical en lugar de horizontal
  @Prop({ default: false, type: Boolean }) accumulated // Define si el grafico es de acumulados
  @Prop({ default: undefined, type: [String] }) pathToValue // Ruta al valor que suma
  @Prop({ default: undefined, type: Function }) dataTransformer // Funcion que transforma los datos si hace falta
  @Prop({ default: true, type: Boolean }) sortLegends
  @Prop() dates // Fecha de inicio y final { start, end }

  legends: Legend[] = []

  get limitDates () {
    const currentDate = dayjs()
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      return {
        start: this.dates.start,
        end: currentDate.format('YYYY-MM-DDTHH:MM:ss'),
      }
    } else {
      return this.dates
    }
  }

  get limitRange () {
    const { start, end } = this.limitDates
    const prevMonthStart = dayjs(start).subtract(1, 'month')
    const prevMonthEnd = prevMonthStart.endOf('month')

    const fullCurrentMonth = this.generateDayRange(start, dayjs(start).endOf('month'), this.monthly)

    const currentMonth = this.generateDayRange(start, end, this.monthly)
    const prevMonth = this.generateDayRange(prevMonthStart, prevMonthEnd, this.monthly)
    return {
      currentMonth: currentMonth.slice(0, currentMonth.length - 1),
      prevMonth: prevMonth.slice(0, fullCurrentMonth.length - 1),
    }
  }

  get currentMonth () {
    const { start } = this.limitDates

    return {
      start: dayjs(start).startOf('month'),
      end: dayjs(start).endOf('month'),
    }
  }

  get currentMonthLength () {
    const { start, end } = this.currentMonth

    return end.diff(start, 'day')
  }

  get limitLength () {
    return this.limitDates.currentMonth.length
  }

  async getData () {
    if (this.debugging) console.log(this.records, this.prevMonthRecords)
    if ([...this.records, ...this.prevMonthRecords].some(record => !record.records)) return
    const { limitRange, getObjectAttribute, isCurrency, monthly, months } = this

    const parsedRecords = {
      currentMonth: _.cloneDeep(this.records),
      prevMonth: _.cloneDeep(this.prevMonthRecords),
    }

    if (this.dataTransformer) {
      parsedRecords.currentMonth = await this.dataTransformer(parsedRecords.currentMonth)
      if (parsedRecords.prevMonth.length > 0) {
        parsedRecords.prevMonth = await this.dataTransformer(parsedRecords.prevMonth)
      }
    }

    for (let i = 0; i < parsedRecords.currentMonth.length; i++) {
      this.$set(parsedRecords.currentMonth[i], 'records', parsedRecords.currentMonth[i].records?.records?.nodes || parsedRecords.currentMonth[i].records?.records || parsedRecords.currentMonth[i].records?.records || parsedRecords.currentMonth[i].records || [])
    }
    for (let i = 0; i < parsedRecords.prevMonth.length; i++) {
      this.$set(parsedRecords.prevMonth[i], 'records', parsedRecords.prevMonth[i].records?.records?.nodes || parsedRecords.prevMonth[i].records?.records || parsedRecords.prevMonth[i].records?.records || parsedRecords.prevMonth[i].records || [])
    }

    const data = parsedRecords.currentMonth.map(record => {
      const { pathToDate, color, name, type } = record
      if (record.dataTransformer) {
        record.records = record.dataTransformer(record.records)
      }

      const serie = limitRange.currentMonth.map(date => {
        const filteredByDate = record.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, pathToDate))).isSame(dayjs(date), monthly ? 'month' : 'day'))
        if (record.pathToValue) {
          let sum = 0
          filteredByDate.forEach(item => {
            sum += getObjectAttribute(item, record.pathToValue)
          })
          return sum
        }
        return filteredByDate.length
      })

      return {
        serie,
        color,
        name,
        type,
      }
    })

    const prevMonthData = parsedRecords.prevMonth.map(record => {
      const { pathToDate, color, name, type } = record
      if (record.dataTransformer) {
        record.records = record.dataTransformer(record.records)
      }

      const serie = limitRange.prevMonth.map(date => {
        return record.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, pathToDate))).isSame(dayjs(date), monthly ? 'month' : 'day')).length
      })

      return {
        serie,
        color,
        name,
        type,
      }
    })

    this.legends = data.map(item => {
      const { name, color, serie } = item
      let value = 0
      serie.forEach(item => value += item !== null ? item : 0)
      value = Math.round(value)
      return {
        name,
        color,
        value,
      }
    })

    this.series = data.map(item => {
      return {
        data: item.serie.map(item => item || 0),
        name: item.name,
        type: item.type,
        color: item.color,
      }
    })

    prevMonthData.forEach(item => {
      this.series.push({
        data: item.serie.map(item => item || 0),
        name: item.name + ' (mes anterior)',
        type: item.type,
        color: lowOpacityColor(item.color),
      })
    })

    if (this.accumulated) {
      for (let i = 0; i < this.series.length; i++) {
        this.series[i].data = this.generateAccumulated(this.series[i].data)
      }
    }

    if (!monthly) {
      this.series.forEach(serie => {
        serie.data = this.fillWithNull(serie.data, this.currentMonthLength)
      })
    }

    this.chartOptions = {
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 3,
        curve: 'straight',
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories: monthly ? this.limitRange.currentMonth.map(date => dayjs(date)) : this.generateDayRange(this.currentMonth.start, this.currentMonth.end, monthly),
        type: 'category',
        labels: {
          formatter (val) {
            return monthly ? months[dayjs(val).format('MM')].short : dayjs(val).format('DD/MM')
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
        },
      },
    }
  }

  get watchData () {
    const { records, dates } = this
    return {
      records,
      dates,
    }
  }

  @Watch('watchData', { immediate: false, deep: true })
  update () {
    this.getData()
  }
  }
