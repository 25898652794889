
  import DashboardCard from '@/components/dashboards/Components/DashboardCard.vue'
  import PeriodRecords from '@/components/dashboards/Generics/PeriodRecords.vue'
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DashboardCard,
    PeriodRecords,
  },
})
  export default class GeneralResults extends Vue {
  @Prop() chartData: Record<string, any>
  @Prop({ default: 'Resultado general', type: String }) title
  @Prop({ default: 1, type: Number }) multiplier
  @Prop({
    default: () => {
      return {
        scheduledInspections: true,
        purchases: true,
        approvedCredits: true,
        signedCredits: true,
        reserves: true,
        sales: true,
      }
    },
  }) displayChart

  @Prop({ default: undefined, type: Number }) idEmployee

  getFinancings (records) {
    const openEvaluations = records
      .filter(loan => !loan.evaluation?.status?.isClosed)
      .filter(loan => {
        const sorted = loan.evaluations.sort((prev, next) => {
          return next.status.order - prev.status.order
        })
        return sorted[0].status.name === 'approved'
      })
    const closedEvaluations = records
      .filter(loan => loan.evaluation?.status?.isClosed)
      .filter(loan => loan.evaluation.evaluation.closingReason.name === 'signed' || loan.evaluation.evaluation.closingReason.name === 'resigned')

    return [...openEvaluations, ...closedEvaluations]
  }

  getExecutiveSales (records) {
    const executives = this.chartData?.settings?.executives?.records
    const staffIDs = executives?.map(executive => executive?.id_executive)

    return records.filter(record => staffIDs?.includes(record.executive))
  }

  getLeadsWithInspections (records) {
    return records.filter(record => {
      let hasInspections = false
      const deals = record.deals
      if (!deals.length) {
        hasInspections = false
      } else {
        deals.forEach(deal => {
          const appraisals = deal.appraisals
          if (!appraisals.length) {
            hasInspections = false
          } else {
            appraisals.forEach(appraisal => {
              hasInspections = Boolean(appraisal.inspection)
            })
          }
        })
      }
      return hasInspections
    })
  }
  }
