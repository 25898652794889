export const chassisColors = {
  Sedán: '#13CE66',
  Hatchback: '#7D67BC',
  SUV: '#6185DB',
  Coupé: '#FAD901',
  'Cabina simple': '#FF9E2C',
  'Cabina y media': '#30426E',
  Camioneta: '#F03738',
  'Station wagon': '#5FECFF',
  Furgón: '#3F345E',
  Minibus: '#FF69D5',
  Van: '#D78AFD',
  Cabriolet: '#0095FF',
  'Motor home': '#FA5A7D',
  'Comercial (Furgón)': '#3F345E',
}
